import React, { useEffect } from 'react';
import "./style.scss"
import Header from './Header/Header';
import Content from './Content/Content';
import Carusel from "./Carusel/Carusel"
import Footer from "./Footer/Footer"
import Popup from './Popup/Popup';
function App() {

  useEffect(() => {
    window.scrollTo({top:0,behavior:'smooth'})
  },[])
  return (
    <div className="App">
      <Header/>
      <Content/>
      {/* <Carusel/> */}
      <Footer/>
      <Popup/>
    </div>
  );
}

export default App;

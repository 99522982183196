import React, { useEffect } from 'react';
import { data } from '../data';
import "./content.scss"

function Content() {
  return (
    <div className='content'>
        <p className='para'>Taubenabwehrsysteme werden an verschiedenen Orten installiert, um Tauben davon abzuhalten, sich niederzulassen und potenzielle Schäden zu verursachen. Diese Systeme dienen dem Schutz von Gebäuden vor Beschädigungen durch Tauben, der Vermeidung von Gesundheitsrisiken durch Taubenkot, der Aufrechterhaltung von Hygiene in öffentlichen Bereichen, der Reduzierung von Lärmbelästigung und der Verhinderung von Nestbildung. Durch die Installation solcher Systeme wird die Taubenpopulation kontrolliert und damit verbundene Probleme minimiert.</p>
        <h2>Effektive Taubenabwehrsysteme</h2>
        {data.map((item,i) => (
        <div key={i} className="single">
            {/* <img src={item.img} alt={`Image ${i}`} /> */}
            <div className="text-container">
            <h1>{item.title}</h1>
            <p>{item.text}</p>
            </div>
            
        </div>
    ))}
          <h2>Professionelle Dienstleistungen</h2>
          <div id='services' className="single">
            <div className="text-container">
            <h1>Reinigung von Taubenkot</h1>
            <p>Unsere Kotreinigungsdienste bieten eine gründliche Entfernung von Taubenkot, um Gesundheitsrisiken zu minimieren und eine saubere Umgebung zu gewährleisten. Unsere erfahrenen Teams verwenden spezielle Ausrüstung, um effektiv und sicher zu reinigen.</p>
            </div>
          </div>

          <div className="single">
            <div className="text-container">
            <h1>Reparatur von bestehenden Systemen</h1>
            <p>Unsere Firma repariert bestehende Systeme zuverlässig und effizient. Mit Fachkenntnissen und einem Fokus auf kosteneffektive Lösungen bringen wir Ihre Anlagen schnell wieder zum Laufen.</p>
            </div>
          </div>

          <div className="single">
            <div className="text-container">
            <h1>Wartung von bestehenden Systemen</h1>
          <p>Unsere Wartungsarbeiten für Taubenabwehrsysteme gewährleisten eine regelmäßige Inspektion und Pflege, um deren Effektivität aufrechtzuerhalten und potenzielle Schäden zu vermeiden. Unsere Fachkräfte kümmern sich professionell um Ihre Taubenabwehrsysteme, damit Sie sich um Ihre anderen Anliegen kümmern können.</p>
            </div>
          </div>

           
           
           

    </div>
  )
}

export default Content
import React from "react";
import "./footer.scss";
import logo from "../Header/Ahmad-fahd-logo.png";
function Footer() {
  let getDate = new Date();
  let year = getDate.getFullYear();
  const email = "ahmad-Fahd@web.de";
  const tel = "015228809013";
  return (
    <footer id="kontakt">
      {/* <img src={logo} alt="" /> */}
      <h2>AHMAD FAHD {year}</h2>
      <a href={`mailto:${email}`}>E-Mail: {email}</a>
      <a href={`tel:${tel}`}>Telefonnummer: {tel}</a>
      <h4>Wilhelmplatz 8</h4>
      <h4>47918 Tönisvorst</h4>
    </footer>
  );
}

export default Footer;

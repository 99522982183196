import React from 'react'
import "./popup.scss"
import 'boxicons'
function Popup() {
  const email = 'ahmad-Fahd@web.de'
  const tel = '015228809013'
  return (
    <div className='popup'>
        <a href={`tel:${tel}`}  className="phone">
        <box-icon type='solid' name='phone'></box-icon>
        </a>
        <a href={`mailto:${email}`} className="email">
        <box-icon name='envelope' type='solid' ></box-icon>
        </a>
    </div>
  )
}

export default Popup
import logo from './baner.jpg'
export const data = [
    {
        title:'Spikes / Taubenabweiser',
        img:logo,
        text:'Spikes sind eine gängige Methode zur Taubenabwehr. Sie werden auf Oberflächen wie Fensterbänken, Dächern oder Geländern installiert, um Tauben daran zu hindern, sich dort niederzulassen. Die Spitzen verhindern, dass Tauben landen können, und zwingen sie, andere Nistplätze zu suchen. Spikes sind effektiv, da sie Tauben physisch abhalten, ohne ihnen Schaden zuzufügen.'
    },
    {
        title:'Spanndrahtsysteme',
        img:logo,
        text:'Das Spanndrahtsystem besteht aus gespannten Drähten, die über potenziellen Lande- und Nistflächen angebracht werden, um Tauben davon abzuhalten, sich dort niederzulassen.'
    },
    {
        title:'Vernetzung',
        img:logo,
        text:'Bei der Vernetzung werden Netze über potenzielle Lande- und Nistflächen gespannt, um Tauben davon abzuhalten, sich dort niederzulassen. Diese Netze sind eine effektive Barriere und verhindern den Zugang zu bestimmten Bereichen, ohne den Tauben Schaden zuzufügen.'
    },
    {
        title:'Elektrosysteme',
        img:logo,
        text:'Elektro Systeme sind eine Taubenabwehrmethode, bei der leichte elektrische Schläge auf bestimmten Oberflächen angebracht werden, um Tauben vom Landen abzuhalten. Diese Schläge sind für Tauben unangenehm, aber nicht schädlich. Sie dienen dazu, die Tauben vom Aufenthalt an den geschützten Bereichen abzuhalten.'
    },
    {
        title:'Sonderanfertigungen',
        img:logo,
        text:'Unsere maßgeschneiderten Abwehrsysteme bieten individuelle Lösungen für Ihre spezifischen Anforderungen. Von der Planung bis zur Umsetzung arbeiten unsere Experten eng mit Ihnen zusammen, um sicherzustellen, dass Ihre Bedürfnisse erfüllt werden und Ihr Abwehrsystem effektiv ist.'
    }
]
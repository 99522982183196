import React from 'react'
import "./header.scss"
import logo from "./adler.png"
function Header() {
  return (
    <header>
        <div className="header-title">
        {/* <img src={logo} alt="" /> */}
        <span>
          <h1 className='logo1'>A</h1>
          <h2 className='logo2'>FAHD</h2>
          <img src={logo} alt="" />
        </span>


        <h1>TAUBENABWEHR</h1>
        <p>Nach Kontaktaufnahme und Terminvereinbarung entsenden wir unser geschultes Personal zur Besichtigung und Besprechung, um das geeignete Abwehrsystem zu ermitteln.</p>

        <div className='header-btns'>
          <a href='#kontakt'>Kontakt</a>
          <a href='#services'>Services</a>
        </div>
        </div>
    </header>
  )
}

export default Header